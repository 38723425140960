/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Myriad Pro';
  src: url('./assets/fonts/MYRIADPRO-REGULAR.OTF') format('opentype');
  font-weight: 400;
}


@font-face {
  font-family: 'Myriad Pro';
  src: url('./assets/fonts/MYRIADPRO-SEMIBOLD.OTF') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('./assets/fonts/MyriadPro-Light.otf') format('opentype');
  font-weight: 300;
}

body {
  font-family: 'Myriad Pro', sans-serif;
}

body {
  font-family: 'Myriad Pro';
}

.error-snackbar {
  --mdc-snackbar-container-color: red;
  --mdc-snackbar-supporting-text-color: white;
}

.success-snackbar {
  --mdc-snackbar-container-color: rgb(196, 255, 173);
  --mdc-snackbar-supporting-text-color: rgb(91, 91, 91);
}

.info-snackbar {
  --mdc-snackbar-container-color: rgb(170, 222, 255);
  --mdc-snackbar-supporting-text-color: rgb(0, 0, 0);
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50
}
